/* eslint-disable no-undef */
//function names must be these due IOs integration

const bridges = {
  ios: (name) => Boolean(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[name]),
  reactNative: () =>
    Boolean(window.ReactNativeWebView && window.ReactNativeWebView.postMessage),
}

function postMessage(name, data) {
  if (bridges.ios(name)) {
    window.webkit.messageHandlers[name].postMessage(JSON.stringify(data ? data : {}))
  }
  if (bridges.reactNative()) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data ? { ...data, name } : { name }))
  }
}

function handleFacebookShareIOS(mediaArray, postDescription) {
  try {
    postMessage('facebook', { url: mediaArray, copy: postDescription })
  } catch (err) {
    console.log('error on facebook sharing')
  }
}

function handleInstagramShareIOS(mediaArray, postDescription) {
  try {
    postMessage('instagram', { url: mediaArray, copy: postDescription })
  } catch (err) {
    console.log('error on instagram sharing')
  }
}

function sendDownloadEvent(mediaArray) {
  try {
    postMessage('download', { url: mediaArray })
  } catch (err) {
    console.log('error on downloading')
  }
}

function handleLeaveWebview() {
  try {
    postMessage('exit', {})
  } catch (err) {
    console.log('error on exiting')
  }
}

function signalAppUserEnteringForMe() {
  try {
    postMessage('meMarketingRequest', {})
  } catch (err) {
    console.log('error on selecting for me flow')
  }
}

function signalAppToHideBackButton() {
  try {
    postMessage('hideBackButton', {})
  } catch (err) {
    console.log('error on hiding back button')
  }
}

function signalAppToShowBackButton() {
  try {
    postMessage('enableBackButton', {})
  } catch (err) {
    console.log('error on signaling app to show back button')
  }
}

function signalNonAuthed() {
  try {
    postMessage('nonAuth', {})
  } catch (err) {
    console.log('error on calling app unauthed')
  }
}

function presentActionSheet(options, cb) {
  try {
    window.av8.actionSheet = (actionID) => {
      if (cb) {
        cb(actionID)
        window.av8.actionSheetAction = () => {}
      }
    }

    postMessage('presentActionSheet', { options, callbackName: 'actionSheet' })
  } catch (err) {
    console.error(err)
  }
}

function sendUserToAppHomeScreen() {
  try {
    postMessage('backHome', {})
  } catch (err) {
    console.log('error on calling app unauthed')
  }
}

function downloadCSVFiles(urlsArray) {
  try {
    postMessage('CSV', { url: urlsArray })
  } catch (err) {
    console.log('error on downloading csv files')
  }
}

function downloadZIPFile(urlsArray) {
  try {
    postMessage('ZIP', { url: urlsArray })
  } catch (err) {
    console.log('error on downloading csv files')
  }
}

function isInsideWebview () {
  return window && (bridges.ios() || bridges.reactNative())
}

export {
  handleFacebookShareIOS,
  handleInstagramShareIOS,
  sendDownloadEvent,
  handleLeaveWebview,
  signalAppUserEnteringForMe,
  signalAppToHideBackButton,
  signalAppToShowBackButton,
  signalNonAuthed,
  presentActionSheet,
  sendUserToAppHomeScreen,
  downloadCSVFiles,
  downloadZIPFile,
  isInsideWebview,
}
