import { createGlobalStyle } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-multi-carousel/lib/styles.css'
import * as fontFiles from './fonts/index'
// Colors
const blue = '#2a29a6'
const black = '#292929'
const lightBlue = '#dddfef'
const red = '#e93e57'
const white = '#ffffff'
const lightGrey = '#777'

export default createGlobalStyle`
  :root {
    --blue: ${blue};
    --blue-opaque: ${blue}80;
    --black: ${black};
    --lightBlue: ${lightBlue};
    --red: ${red};
    --white: ${white};
    --light-grey: ${lightGrey}
  }

  @font-face {
    font-family: BookmaniaBold;
    src: url(${fontFiles.Bookmania});
  }

  @font-face {
    font-family: BookmaniaLightItalic;
    src: url(${fontFiles.BookmaniaItalic});
  }

  @font-face {
    font-family: StyreneItalic;
    src: url(${fontFiles.StyreneItalic});
  }

  @font-face {
    font-family: StyreneRegular;
    src: url(${fontFiles.StyreneRegular});
  }

  @font-face {
    font-family: BookmaniaRegularItalic;
    src: url(${fontFiles.BookmaniaRegularItalic});
  }

  @font-face {
    font-family: StyreneMedium;
    src: url(${fontFiles.StyreneMedium});
  }

  @font-face {
    font-family: StyreneA;
    font-weight: 300;
    src: url(${fontFiles.StyreneRegular});
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: StyreneA;
    font-weight: 400;
    src: url(${fontFiles.StyreneRegular});
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: StyreneA;
    font-weight: 500;
    src: url(${fontFiles.StyreneMedium});
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: CanelaLight;
    src: url(${fontFiles.CanelaLight});
  }


  @font-face {
    font-family: RobotoRegular;
    src: url(${fontFiles.RobotoRegular});
  }

  @font-face {
    font-family: Pragmatica;
    src: url(${fontFiles.Pragmatica});
  }

  @font-face {
    font-family: MoonTimeRegular;
    src: url(${fontFiles.MoonTimeRegular});
  }

  /* New font-face for RTR v2 (which uses the .ttf real font name) */
  @font-face {
    font-family: 'Styrene A';
    src: url(${fontFiles.StyreneRegular});
  }

  @font-face {
    font-family: 'Styrene A';
    src: url(${fontFiles.StyreneMedium});
    font-weight: 500;
    font-style: normal;
  }


  @font-face {
    font-family: 'Styrene A';
    src: url(${fontFiles.StyreneItalic});
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Pragmatica';
    src: url(${fontFiles.Pragmatica});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
      font-family: 'Canela';
      src: url(${fontFiles.CanelaLight});
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
    font-family: 'MoonTime';
    src: url(${fontFiles.MoonTimeRegular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${fontFiles.RobotoRegular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bookmania';
    src: url(${fontFiles.Bookmania});
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bookmania';
    src: url(${fontFiles.BookmaniaRegularItalic});
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Bookmania';
    src: url(${fontFiles.BookmaniaItalic});
    font-weight: 300;
    font-style: italic;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
  }

  *:focus {
    outline: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  body {
    margin: 0px !important;
    /* width: 375px !important; */
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
  }

  .MuiDialog-paperFullWidth {
    /* width: calc(100% - 40px); */
  }

  .react-datepicker-wrapper {
    width: 98%;
  }

  .MuiAppBar-root .container + .centeredTextWrapper {
    margin: 0 0 16px;
  }
  
  .react-pdf__Document {
    max-height: 100%;
    max-width: 100%;
    max-height: 400px;
  }
  .react-pdf__Page {
    height: 100%;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    max-width: 400px;
    max-height: 400px;
  }
  .react-card-front {
    transform-style: initial;
  }
  .react-card-back {
    transform-style: initial;
  }
  .ReactCrop__drag-handle::after {
    width: 20px;
    height: 20px;
  }
  .ReactCrop .ord-sw {
    margin-bottom: -10px;
    margin-left: -10px;
  }
  .ReactCrop .ord-se {
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .ReactCrop .ord-nw {
    margin-top: -10px;
    margin-left: -10px;
  }
  .ReactCrop .ord-ne {
    margin-top: -10px;
    margin-right: -10px;
  }
`
