import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useAuthContext } from '../../context/AuthContext'
import { useRequestContext } from '../../context/RequestContext'
import Loading from '../common/Loading'
import { getAddressByListingId } from '../../services/addressServices'
import { checkTokenValidation } from '../../services/marketingServices'
import { getAgentProfile } from '../../services/authService'
import { signalNonAuthed } from '../../services/appServices'
import { setAuthToken } from '../../services/api'
import { useConfirmationDialog } from './ModalDialog'

export const getIsEmbedded = () => {
  const urlParams = new URLSearchParams(location.search)
  let isEmbedded = localStorage.getItem('isEmbedded')

  //isEmbeddedFromLocalStorage
  if (isEmbedded) {
    return isEmbedded === 'true'
  } else {
    //isEmbeddedFromParams
    isEmbedded = urlParams.get('mbd') === 'true'
    localStorage.setItem('isEmbedded', isEmbedded)
    return isEmbedded
  }
}

export const getAgentId = () => {
  const urlParams = new URLSearchParams(location.search)
  const agentId = urlParams.get('agentId')
  return agentId
}

const AppInitialization = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const {
    setListingAddressCtx,
    setSelectedPropertyCtx,
    setIsEmbeddedCtx,
  } = useRequestContext()
  const { setAgentId, setIsAuthed, setAuthSBL, setAgentData } = useAuthContext()
  const { getConfirmation } = useConfirmationDialog()
  const urlParams = new URLSearchParams(location.search)

  const setupIsEmbedded = () => {
    setIsEmbeddedCtx(getIsEmbedded())
  }

  const handleUnauthedCall = () => {
    const isEmbbeded = getIsEmbedded()
    if (isEmbbeded) {
      signalNonAuthed()
    } else {
      window.location.href = `${window.location.origin}/account`
    }
  }

  const setupAuthFromAuthSBL = async () => {
    const authSBL =
      'getAuthSBLInstance' in window.av8.auth
        ? window.av8.auth.getAuthSBLInstance()
        : null

    if (!authSBL) {
      // TODO: use Sentry directly to warn about this critical issue with a fallback
      Sentry.captureException(
        `No auth sbl found at "${process.env.REACT_APP_SBL_BUNDLE_URL}"`,
      )
      throw new Error(
        `No auth sbl found at "${process.env.REACT_APP_SBL_BUNDLE_URL}"`,
      )
    }
    setAuthSBL(authSBL)
    const authChangeHandler = (credentials) => {
      const { token = null } = credentials || {}
      setIsAuthed(!!token)
      setAuthToken(token)
      return getAgentProfile().then(({ success, data }) => {
        setIsAuthed(!!success)
        setAgentId(data?.id || null)
        setAgentData(data || null)
        return success
      })
    }
    if (authSBL.addOnAuthChangeListener && authSBL.getCredentials) {
      authSBL.addOnAuthChangeListener(authChangeHandler)

      const credentials = await authSBL.getCredentials()

      const { token = null } = credentials || {}

      if (token) {
        return authChangeHandler({ token })
      }
    } else {
      // TODO: use Sentry to warn about this critical issue
      console.error(
        `Incompatible authSBL detected from "${process.env.REACT_APP_SBL_BUNDLE_URL}"!`,
      )
    }
    return setupAuthFromQueryParams()
  }

  const setupAuthFromQueryParams = async () => {
    const agentId = urlParams.get('agentId')
    const token = urlParams.get('token')

    if (agentId) {
      setAgentId(agentId)
    }

    if (token && agentId) {
      setAuthToken(token)
      const response = await checkTokenValidation(agentId)
      if (response.success) {
        setIsAuthed(true)

        getAgentProfile().then(({ data }) => {
          setAgentData(data || null)
        })
      }
    }
  }

  const setupAddressFromQueryParams = async () => {
    const listingId = urlParams.get('listingId')

    if (listingId) {
      const response = await getAddressByListingId(listingId)
      if (response.success) {
        const {
          firstAddressLine,
          secondAddressLine,
          city,
          state,
          zip,
        } = response.data
        const listingAddress = {
          firstAddressLine,
          secondAddressLine,
          city,
          state,
          zip,
        }
        setListingAddressCtx(listingAddress)
        const addressString = Object.values(listingAddress)
          .filter((notNull) => notNull)
          .join(', ')
        setSelectedPropertyCtx(addressString)
      } else if (response.statusCode === 401 || response.statusCode === 403) {
        await getConfirmation({
          message: "Seems like you don't have authorization.",
          firstButtonText: 'Ok',
          onDismiss: () => handleUnauthedCall(),
        }).then(() => handleUnauthedCall())
      }
    }
  }

  useEffect(() => {
    setupIsEmbedded()

    setupAuthFromAuthSBL().then(() => {
      setupAddressFromQueryParams().then(() => {
        setLoading(false)
      })
    })
  }, [])

  if (loading) {
    return <Loading />
  }
  return <>{children}</>
}

export default AppInitialization
